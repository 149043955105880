export const LOCAL_STORAGE_KEYS = {
  CONTACT_LIMIT_REACHED: 'contactLimitReached',
  CONTACT_LIMIT_WARNING: 'contactLimitWarning',
  DISMISSED_UPDATES: 'dismissedUpdates',
  WIDGET_BUILDER: 'widgetBubble_',
  DRAFT_MESSAGES: 'draftMessages',
  COLOR_SCHEME: 'color_scheme',
  DISMISSED_LABEL_SUGGESTIONS: 'labelSuggestionsDismissed',
  MESSAGE_REPLY_TO: 'messageReplyTo',
};
