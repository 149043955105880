<template>
  <Banner
    v-if="alertMessage"
    color-scheme="alert"
    :banner-message="alertMessage"
    has-action-button
    :action-button-label="$t('ALERT_NOTIFICATION.PAINEL_LINK_TEXT')"
    :action-button-url="painelUrl"
  />
</template>

<script>
import AccountAPI from '../api/account';
import Banner from 'dashboard/components/ui/Banner.vue';

export default {
  name: 'AlertNotification',
  components: { Banner },
  data() {
    return {
      alertMessage: null,
      painelUrl: chatwootConfig.alertPaymentUrl,
    };
  },
  async mounted() {
    try {
      const { data: response } = await AccountAPI.getAccountDates();

      if (response?.cancellation_date && response?.deletion_date) {
        this.alertMessage = this.$t('ALERT_NOTIFICATION.BOTH_DATES', {
          cancellation_date: this.formatDate(response.cancellation_date),
          deletion_date: this.formatDate(response.deletion_date),
        });
      } else if (response?.deletion_date) {
        this.alertMessage = this.$t('ALERT_NOTIFICATION.ONLY_DELETION_DATE', {
          deletion_date: this.formatDate(response.deletion_date),
        });
      }
    } catch (error) {
      this.alertMessage = null;
    }
  },
  methods: {
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(this.$root.$i18n.locale, options);
    },
  },
};
</script>


