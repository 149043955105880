<script>
import Banner from 'dashboard/components/ui/Banner.vue';
import { mapGetters } from 'vuex';
import { testAccountLimit, isLessThanTenPercentLeft } from 'dashboard/helper/featureHelper';

import { LOCAL_STORAGE_KEYS } from 'dashboard/constants/localStorage';
import { LocalStorage } from 'shared/helpers/localStorage';

export default {
  components: { Banner },
  data() {
    return {
      userDismissedBanner: false,
    };
  },
  computed: {
    ...mapGetters({
      accountLimits: 'accounts/getAccountLimits',
    }),
    contactsLimitMessage() {
      const limits = this.accountLimits;
      if (!limits) return '';

      const { contacts } = limits;
      const { allowed, consumed } = contacts;

      if (isLessThanTenPercentLeft(contacts)) {
        return this.$t('GENERAL_SETTINGS.CONTACTS_UPGRADE_WARNING', {
          allowed,
          consumed,
        });
      }

      if (this.isLimitExceeded()) {
        return this.$t('GENERAL_SETTINGS.CONTACTS_UPGRADE_ALERT', {
          allowed,
        });
      }

      return '';
    },
    actionButtonMessage() {
      return this.$t('GENERAL_SETTINGS.OPEN_BILLING');
    },
    shouldShowBanner() {
      const limits = this.accountLimits;
      if (!limits) return false;

      const { contacts } = limits;

      const isNearLimit = isLessThanTenPercentLeft(contacts);
      const hasReachedLimit = testAccountLimit(contacts);

      if (hasReachedLimit && this.isDismissedToday(LOCAL_STORAGE_KEYS.CONTACT_LIMIT_REACHED)) {
        return false;
      }

      if (isNearLimit && this.isDismissedToday(LOCAL_STORAGE_KEYS.CONTACT_LIMIT_WARNING)) {
        return false;
      }

      return hasReachedLimit || isNearLimit;
    },
  },
  async mounted() {
    await this.fetchLimits();
    this.initializeBannerState();
  },
  methods: {
    async fetchLimits() {
      await this.$store.dispatch('accounts/limits');
    },
    initializeBannerState() {
      const limits = this.accountLimits;
      if (!limits) return;

      const { contacts } = limits;

      const isNearLimit = isLessThanTenPercentLeft(contacts);
      const hasReachedLimit = testAccountLimit(contacts);


      if (
        (hasReachedLimit && this.isDismissedToday(LOCAL_STORAGE_KEYS.CONTACT_LIMIT_REACHED)) ||
        (isNearLimit && this.isDismissedToday(LOCAL_STORAGE_KEYS.CONTACT_LIMIT_WARNING))
      ) {
        this.userDismissedBanner = true;
      } else {
        this.userDismissedBanner = false;
      }
    },
    isLimitExceeded() {
      const limits = this.accountLimits;
      if (!limits) return false;

      const { contacts } = limits;
      return testAccountLimit(contacts) || isLessThanTenPercentLeft(contacts);
    },
    dismissContactLimitBanner() {
      const limits = this.accountLimits;
      if (!limits) return;

      const { contacts } = limits;

      const isNearLimit = isLessThanTenPercentLeft(contacts);
      const hasReachedLimit = testAccountLimit(contacts);

      const today = new Date().toISOString().split('T')[0];

      if (hasReachedLimit) {
        LocalStorage.set(LOCAL_STORAGE_KEYS.CONTACT_LIMIT_REACHED, today);
      } else if (isNearLimit) {
        LocalStorage.set(LOCAL_STORAGE_KEYS.CONTACT_LIMIT_WARNING, today);
      }

      this.userDismissedBanner = true;
    },
    isDismissedToday(key) {
      const today = new Date().toISOString().split('T')[0];
      const dismissedDate = LocalStorage.get(key);
      return dismissedDate === today;
    },
  },
};
</script>

<template>
  <Banner
    v-if="shouldShowBanner && !userDismissedBanner"
    color-scheme="alert"
    :banner-message="contactsLimitMessage"
    has-close-button
    @close="dismissContactLimitBanner"
  />
</template>

